const FaqData = [
    {
            },
    {
          },
    {
            },
]

export {FaqData};
